* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.animation-out-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    pointer-events: none;
}

.animation-out-container .transition {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
}

.animation-in-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: black;
    transform-origin: top;
    pointer-events: none;
}


